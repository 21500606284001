@import '../../platformui/ZoomContainer/styles.scss';
@include ZoomContainer(
  $cta: c('cta'),
  $muted: c('black'),
  $linkHover: c('cta-dark'),
  $white: c('white'),
  $black: c('black')
) {
  margin-top: 40px;
  padding-top: 45px;

  .zoom-fullscreen__modal {
    &-open {
      top: -40px;
      span {
        margin-right: 40px;
      }

      &:before {
        background-color: rgba(c('grey-600'), 0.2);
      }

      &:before,
      &:after {
        height: 26px;
        width: 26px;
        font-size: 14px;
        margin-right: 0.015em;
      }

      &:hover,
      &:focus {
        &::after {
          transform: scale(1);
          font-size: 16px;
        }
      }
    }
    &-close {
      &:has(span.zoom-fullscreen__modal-close__text) {
        top: 25px;
        left: calc(100% - 150px);
        
        @include breakpoint(large) {
          top: 85px;
          left: calc(100% - 220px);
        }

        &:after {
          background: url('../../framework/images/menu-x.svg');
          height: 30px;
          width: 30px;
          font-size: 26px;
          top: 7px;
        }
      }

      &__text {
        color: c('main-dark');
      }
    }

    &-panel__content {
      padding: 60px;

      &:has(img) {
        max-width: 1400px;
        padding-top: 20px;
        .zoom-fullscreen__modal-close {
          position: relative;
          top: -10px;
          left: calc(100% - 100px);
        }
      }

      .table__heading,
      .table__description {
        width: 100%;
      }
    }
  }

  &:has(.table-overflow) .zoom-fullscreen__modal-open {
    top: 5px;

    @include site-theme(vhb) {
      color: c('nav-dark');
    }
  }

  .table-heading {
    max-width: calc(100% - 200px);
  }

  .table-overflow {
    p:first-of-type {
      margin-bottom: 10px;
    }
  }
}
