@import '../../../platformui/TableWrapper/styles';

.table {
  &__heading {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: normal;
  }

  &__footer {
    font-style: italic;
  }

  &-heading {
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 15px;
  }

  &-overflow {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    display: grid;
    padding-top: 2px;

    @media print {
      overflow-x: visible !important;
    }

    &--has-caption {
      margin-top: 2px;
      border-top: 2px solid rgba(c('grey-600'), 0.2);
    }

    &::-webkit-scrollbar {
      background-color: c('grey-100');
      -webkit-appearance: none;
      width: 14px;
      height: 18px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 3px solid transparent;
      background: c('blue');
      background-clip: content-box;
    }

    .table__caption {
      background: rgba(c('grey-600'), 0.05);
      color: c('grey-600');
      caption-side: top;
      font-weight: normal;
      padding: 14px 20px;
      text-align: left;
      display: block;
      margin-bottom: 2px;
    }

    .table__heading {
      text-transform: uppercase;
      letter-spacing: 2px;
      margin: 0px;
      width: rem-calc(600);

      &--with-description {
        margin-bottom: 8px;
      }
    }

    .table__description {
      margin: 0px !important;
      width: rem-calc(600);
    }

      td {
        background: none;
      }

      td > ul {
        list-style-type: disc;

        li::marker {
          color: c('main');
          font-size: 1.2em;
        }
      }

      td > ol {
        list-style: auto;
      }
    }
  }

table {
  border-collapse: separate;
  border-spacing: 2px;
  border-color: $white;
  clear: both;
  margin-bottom: rem-calc(30);
  min-width: 100%;

  .table-wrapper & {
    margin-bottom: rem-calc(10);
  }

  tbody {
    tr:nth-child(even) {
      td {
        @include site-theme(vhb) {
          background: rgba(c('grey-600'), 0.1);
        }

        @include site-theme(rhb) {
          background: rgba(c('grey-550'), 0.05);
        }

        @media print {
          background: none;
        }

        padding: rem-calc(7) rem-calc(15);
      }
    }

    tr:nth-child(odd) {
      td {
        @include site-theme(vhb) {
          background: rgba(c('grey-550'), 0.05);
        }

        @include site-theme(rhb) {
          background: rgba(c('grey-600'), 0.1);
        }

        @media print {
          background: none;
        }

        padding: rem-calc(7) rem-calc(15);
      }
    }

    tr:first-child {
      @media print {
        border-bottom: 2px solid $black;
      }
    }

    tr:hover {
      td {
        background: rgba(c('grey-600'), 0.2);
      }

      th {
        @include site-theme(vhb) {
          background: rgba(c('main-light'), 0.3);
        }

        @include site-theme(rhb) {
          background: rgba(c('secondary'), 0.3);
        }
      }
    }

    @media print {
      border-top: 2px solid $black;
      border-bottom: 2px solid $black;
    }
  }

  td {
    color: c('grey-600');
  }

  th, thead {
    color: c('grey-600');
    font-weight: normal;

    @include site-theme(vhb) {
      background: rgba(c('main-light'), 0.2);
    }

    @include site-theme(rhb) {
      background: rgba(c('secondary'), 0.2);
    }

    padding: rem-calc(7) rem-calc(20);
    text-align: left;
    white-space: nowrap;

    [dir='rtl'] & {
      text-align: right;
    }

    @media print {
      background: none;
    }
  }

  thead th {
    font-weight: bold;
  }

  caption {
    background: rgba(c('grey-600'), 0.05);
    border-left: solid 2px $white;
    border-right: solid 2px $white;
    color: c('grey-600');
    font-size: rem-calc(18);
    letter-spacing: 2px;
    padding: rem-calc(14) rem-calc(20);
    position: relative;
    text-align: left;
    text-transform: uppercase;

    &:before {
      border-top: solid 2px rgba(c('grey-600'), 0.2);
      border-bottom: solid 2px $white;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      @media print {
        border-color: $black;
        border-bottom: none;
      }
    }

    [dir='rtl'] & {
      text-align: right;
    }

    @media print {
      background: none;
      color: $black;
    }
  }

  tfoot {
    td {
      font-size: rem-calc(14);
      padding: rem-calc(7) rem-calc(0) rem-calc(10);
      position: relative;

      &:after {
        @include site-theme(vhb) {
          background: rgba(c('main-light'), 0.3);
        }

        @include site-theme(rhb) {
          background: rgba(c('secondary'), 0.3);
        }

        @media print {
          background: $black;
          height: 1px;
        }

        bottom: 0;
        content: '';
        left: 0;
        height: 2px;
        position: absolute;
        width: 87px;
      }

      p {
        font-size: inherit;
      }
    }
  }

  &.wide,
  &.table-wide-secondary {
    @if $editor == 'true' {
      editmenuname: Utfallande tabell;
    }
  }

  &.table-wide-secondary,
  &.table-secondary {
    th {
      background: rgba(c('secondary'), 0.2);
    }

    tbody {
      tr:hover {
        th {
          background: rgba(c('secondary'), 0.3);
        }
      }
    }

    tfoot {
      td {
        &:after {
          background: rgba(c('secondary'), 0.3);
        }
      }
    }
  }
}

@include TableWrapper($scrollbarColor: rgba(c('grey-600'), 0.4)) {
  .table-wrapper {
    &-inner {
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(c('grey-600'), 0.1);
        box-shadow: inset 3px 3px 3px 0 rgba(72, 72, 72, 0.2);
      }
    }
  }
}
