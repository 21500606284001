@mixin ReferenceNumber($isCounter: false, $counterElement: li) {
  position: relative;
  font-style: normal;
  font-size: 11px;

  @if $isCounter == true {
    &:before {
      content: counter($counterElement);
      counter-increment: $counterElement;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      bottom: 1px;
      right: -17px;
      height: 16px;
      min-width: 16px;
      border: 1px solid $black;
      border-radius: 4px;
    }
  } @else {
    background-color: transparent;
    padding: 0 4px;
    border: 1px solid $black;
    border-radius: 4px;
  }
  @content;
}

@mixin ReferenceCounter($counterElement: li) {
  counter-reset: $counterElement;

  #{$counterElement} {
    i {
      @include ReferenceNumber(
        $isCounter: true,
        $counterElement: $counterElement
      );
    }
  }
  @content;
}

// CLASS FOR EPI EDITOR
.reference__number {
  white-space: nowrap;
  
  @if $editor == 'true' {
    editmenuname: Referensnummer;
    changeelementtype: true;
  }
  @include ReferenceNumber();
}
